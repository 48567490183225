import axios from 'axios';
//@ts-ignore
import ECommerceConf from '../conf/ECommerceConf.ts';
//@ts-ignore
import Ticket from './Ticket.ts';
//@ts-ignore
// import User from './types/account/User.ts';
//@ts-ignore
// import Employee from './types/account/Employee.ts';
//@ts-ignore
// import EmployeeGeneralList from '@/scanclick/types/account/EmployeeGeneralList.ts';

export default class AccountsManager {

    constructor() {



    }

    getTicketTypes() {

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        query {
  
                            getTicketTypes {
                            id_ticket_type
                            type
                            rating_top
                            rating_bottom
                          }
                        }`

            }).then((response) => {
                resolve(response.data.data.getTicketTypes);
            })

        });

    }

    getTickets(queryTicket) {

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        query {
  
                            getTickets(queryTicket: ${JSON.stringify(queryTicket).replace(/"([^"]+)":/g, '$1:')}) {
                             id_ticket
                             created_at
                             resolved_at
                             closed_at
                             title
                             ticket
                             ticket_type {
                               id_ticket_type
                               type
                               rating_top
                               rating_bottom
                             }
                             customer_observation
                             customer_rating
                             id_sender
                             id_resolver
                             sender_name
                             resolver_name
                             response
                             related_orders
                            }
                        }`

            }).then((response) => {
                resolve(response.data.data.getTickets);
            })

        });
        
    }

    createTicket(newTicket) {

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        mutation {

                            createTicket(newTicket: ${JSON.stringify(newTicket).replace(/"([^"]+)":/g, '$1:')}) {
                                id_ticket
                                id_sender
                                id_resolver
                                ticket_type {
                                    id_ticket_type
                                    type
                                    rating_bottom
                                    rating_top
                                }
                                created_at
                                resolved_at
                                closed_at
                                title
                                ticket
                                response
                                customer_rating
                                customer_observation
                            }
                        }
                        `

            }).then((response) => {

                resolve(response.data.data.createTicket);

            }).catch((error) => {

                resolve([]);

            });
        });        


    }

    updateTicket(updateTicket) {

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        mutation {

                            updateTicket(updateTicket: ${JSON.stringify(updateTicket).replace(/"([^"]+)":/g, '$1:')}) {
                                id_ticket
                                id_sender
                                id_resolver
                                ticket_type {
                                    id_ticket_type
                                    type
                                    rating_bottom
                                    rating_top
                                }
                                created_at
                                resolved_at
                                closed_at
                                title
                                ticket
                                response
                                customer_rating
                                customer_observation
                            }
                        }
                        `

            }).then((response) => {

                resolve(response.data.data.updateTicket);

            }).catch((error) => {

                resolve([]);

            });
        });        


    }    

    authenticateUser(username, password) {
        const crypto = require('js-sha1');
        console.log(`
        query {

            authenticateUser(userLoginInput: {
              username: "${username}",
              password: "${crypto(password)}"

            }) {
              id
              fullName
              username
              avatar
              role
              email
              ability {
                action
                subject
              }
              extras {
                eCommerceCartItemsCount
              }
            }
          }
          `);

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        query {

                            authenticateUser(userLoginInput: {
                              username: "${username}",
                              password: "${crypto(password)}"

                            }) {
                              id
                              fullName
                              username
                              avatar
                              role
                              email
                              ability {
                                action
                                subject
                              }
                              extras {
                                eCommerceCartItemsCount
                              }
                            }
                          }
                          `

            }).then((response) => {

                resolve(response.data.data.authenticateUser);

            }).catch((error) => {

                resolve([]);

            });
        });

    }

    getCustomer(idCustomer) {

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        query {
                            getCustomer(idCustomer:${idCustomer}) {
                              id_scanclick_customers
                              fk_scanclick_users_customers_admin
                              fk_scanclick_subscriptions_customers
                              created_at
                              paused_at
                              suspended_at
                              name
                              gov_id
                              logo
                              debt
                              admin {
                                avatar
                                company
                                created_at
                                email
                                email_verified_at
                                fk_user_customization
                                id_scanclick_users
                                password
                                names
                                remember_token
                                surnames
                                updated_at
                              }
                              subscription {
                                id_scanclick_subscriptions
                                name
                                plan_name
                                unit_price
                                discount_stage_one
                                discount_stage_two
                                discount_stage_three
                              }
                            }
                          }
                          `

            }).then((response) => {

                resolve(response.data.data.getCustomer);

            }).catch((error) => {

                resolve([]);

            });
        });
    }

    getUser(idUser) {

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        query {
                            getUser(idUser:${idUser}) {
                                avatar
                                company
                                created_at
                                email
                                email_verified_at
                                fk_user_customization
                                id_scanclick_users
                                password
                                names
                                remember_token
                                surnames
                                updated_at
                                company_area {
                                    id_companies_areas
                                    name
                                }
                            }
                          }
                          `

            }).then((response) => {

                resolve(response.data.data.getUser);

            }).catch((error) => {

                resolve([]);

            });
        });

    }

    getCountries(status) {

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        query {
                            getCountries(status: ${status}) {
                              id_ecommerce_countries
                              name_spanish
                              name_english
                              name_native
                              iso_2
                              iso_3
                              phone_code
                              status
                            }
                        }`
            }).then((response) => {

                resolve(response.data.data.getCountries);

            }).catch((error) => {

                resolve([]);

            });
        });

    }

    getCustomerLocations(idCustomer) {

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        query {
                            getCustomerLocations(idCustomer: ${idCustomer}) {
                                  id_scanclick_locations
                                  fk_city
                                  fk_country
                                  fk_customer
                                  city_name
                                  country_name
                                  customer_name
                                  lat
                                  lng
                                  name
                                  description
                                  postal_address
                            }
                      }`
            }).then((response) => {

                resolve(response.data.data.getCustomerLocations);

            }).catch((error) => {

                resolve([]);

            });
        });

    }
    
    async getProviderCatalog(shortProviderInput) {
        return new Promise((resolve, reject) => {

            // console.log('taxi ', JSON.stringify(newService).replace(/"([^"]+)":/g, '$1:').replace('\\\"', '\''));

            axios.post(new ECommerceConf().getServerURL() + '/lavarclickconsole/ecommerceMicroService', {
                query: `query {
                    getProviderCatalog(shortProviderInput: ${JSON.stringify(shortProviderInput).replace(/"([^"]+)":/g, '$1:')})  {
                    id_service
                    id_service_item
                    label
                    is_new
                    id_provider

                }

                }`
            }).then((getProviderCatalog) => {
                console.log('resultado ', getProviderCatalog.data.data.getProviderCatalog);
                resolve(getProviderCatalog.data.data.getProviderCatalog);


            });

        });

    }

    updateUserStatus(newUserStatus) {

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        mutation {
                            updateUserStatus(newUserStatus: ${JSON.stringify(newUserStatus).replace(/"([^"]+)":/g, '$1:')}) {
                                id_user
                                status
                                result
                            }
                      }`
            }).then((response) => {

                resolve(response.data.data.updateUserStatus);

            }).catch((error) => {

                resolve([]);

            });
        });

    }

    updateProviderCatalog(catalogUpdate) {

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        mutation {
                            updateProviderCatalog(catalogUpdate: ${JSON.stringify(catalogUpdate).replace(/"([^"]+)":/g, '$1:')}) {
                                id_service
            id_service_item
            label
            is_new
            id_provider
                            }
                      }`
            }).then((response) => {

                resolve(response.data.data.updateProviderCatalog);

            }).catch((error) => {

                resolve([]);

            });
        });

    }
    getCustomerAreas(idCustomer) {

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        query {
                            getCustomerAreas(idCustomer: ${idCustomer}) {
                                id_companies_areas
                                name
                            }
                      }`
            }).then((response) => {

                resolve(response.data.data.getCustomerAreas);

            }).catch((error) => {

                resolve([]);

            });
        });

    }

    createEmployee(newEmployee) {

        delete newEmployee.ability;
        delete newEmployee.customer;
        delete newEmployee.extras;
        delete newEmployee.scanclick_user;
        delete newEmployee.fullName;
        const crypto = require('js-sha1');
        newEmployee.newPassword = crypto(newEmployee.newPassword);

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        mutation {

                            createEmployee(newEmployee: ${JSON.stringify(newEmployee).replace(/"([^"]+)":/g, '$1:')}) {
                                id
                            }
                        }
                        `

            }).then((response) => {

                resolve(response.data.data.createEmployee);

            }).catch((error) => {

                resolve([]);

            });
        });
    }

    getCities(fk_country) {

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        query {

                            getCities(status: ${fk_country}) {
                              id_ecommerce_cities
                              id_country
                              state_code
                              state_name
                              city_code
                              city_name
                              status
                              lat_city
                              lng_city
                            }
                          
                          }`
            }).then((response) => {

                resolve(response.data.data.getCities);

            }).catch((error) => {

                resolve([]);

            });
        });

    }       

    getEmployeePartners(partnersGeneralListinput) {

        return new Promise((resolve, reject) => {

            axios.post(new ECommerceConf().getServerURL()+'/lavarclickconsole/ecommerceMicroService', {
                        query: `
                        query {
                            getEmployeePartners(partnersGeneralListinput: {
                                id_current_user: ${partnersGeneralListinput.id_current_user},
                                id_customer: ${partnersGeneralListinput.id_customer}

                            }) {
                                avatar
                                total
                                invoiceStatus
                                created_at
                                dueDate
                                balance
                                service
                                issuedDate
                                id
                                partner {

                                    address
                                    company
                                    companyEmail
                                    country
                                    contact
                                    name
                                    id_employee
                                    type                                  

                                    }
                            }
                        }
                        `

            }).then((response) => {

                resolve(response.data.data.getEmployeePartners);

            }).catch((error) => {

                resolve([]);

            });
        });

    }


}
